import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import MakeHtml from '../components/commons/MakeHtml';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';
import Contact from '../components/form/Contact';

export function ContactPageTemplate({ title, html }) {
	return (
		<div className="medium-contain contact-wrapper">
			<div className="contact-from-wrappper">
				<Contact />
			</div>
			<div className="contact-markdown-wrapper">
				<h2>Nos coordonnées</h2>
				<div className="markdown" dangerouslySetInnerHTML={{ __html: html }} />
			</div>
			<style jsx>{`
				h1 {
					text-align: center;
				}
				.contact-wrapper {
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;
					padding: 10px 0;
				}
				.contact-from-wrappper {
					width: 100%;
					padding: 60px 20px;
					box-sizing: border-box;
				}
				.contact-markdown-wrapper {
					width: 100%;
					padding: 60px 20px;
					box-sizing: border-box;
					text-align: center;
				}

				@media (min-width: 1200px) {
					.contact-wrapper {
						display: flex;
						align-items: flex-start;
						justify-content: space-around;
						flex-direction: row;
					}
					.contact-from-wrappper {
						width: 50%;
					}
					.contact-markdown-wrapper {
						width: auto;
						padding: 60px 20px;
						box-sizing: border-box;
						text-align: left;
					}
				}
			`}</style>
		</div>
	);
}

export default function ContactPage({ data }) {
	const { frontmatter, html } = data.markdownRemark;

	return (
		<Layout title={frontmatter.seo.seoTitle} description={frontmatter.seo.seoDescription}>
			<ContactPageTemplate title={frontmatter.title} html={html} />
		</Layout>
	);
}

export const pageQuery = graphql`
	query ContactPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
			html
			frontmatter {
				title
				seo {
					seoDescription
					seoTitle
				}
			}
		}
	}
`;
